import React from 'react';

export const GotoLink = ({ goto, label, current = false } : { goto: string, label: string, current?: boolean }) => {
  
  const handleOnClick = (e: any) => {
    e.preventDefault();
    const id = goto;
    const yOffset = -100;
    const element = document.getElementById(id)!;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  };
  return (
    <a
      aria-current="page"
      className={`w-nav-link ${current ? 'button' : 'navigation-item w--current'}`}
      onClick={handleOnClick}
    >
      {label}
    </a>
  );
};