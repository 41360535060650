import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {GotoLink} from '../misc/gotoLink';

export const Navigation = () => {
  return (
    <div className="navigation w-nav">
      <div className="navigation-items">
        <a href="#" className="logo-link w-nav-brand" aria-label="home">
          <div className="div-block-4"/>
          <StaticImage
            src="../../images/logo_3.png"
            quality={100}
            formats={["png",]}
            alt="Elvis Baltais"
            className={'logo-image'}
          />
          <div className="text-block-2">Elvis Baltais</div>
        </a>
        <div className="navigation-wrap">
          <nav role="navigation" className="navigation-items w-nav-menu">
            <GotoLink goto={'about'} label={'About'}/>
            {/*<GotoLink goto={'services'} label={'Services'}/>*/}
            <div className="div-block-2">
              <GotoLink goto={'contact'} label={'Contacts'} current={true}/>
            </div>
          </nav>
          <div className="menu-button w-nav-button" style={{"WebkitUserSelect": "text"}} aria-label="menu" role="button"
               tabIndex={0} aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
              <StaticImage
                src="../../images/5fa252663b6e7c73319c0c7b_menu-icon.png"
                quality={100}
                formats={["png",]}
                alt="menu icon"
                className={'menu-icon'}
                width={22}
              />
          </div>
        </div>
      </div>
      <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"/>
    </div>
  );
};