import React from 'react';
import './main.css';
import {StaticImage} from 'gatsby-plugin-image';
import {SideSocialIcons} from './misc/side-social-icons/sideSocialIcons';
import Seo from '../components/seo'
import {Navigation} from './navigation/Navigation';

export const Main = () => {
  return (
    <>
      <Seo title="Home" />
      <Navigation />
      { false && <SideSocialIcons />}
      <div className="section">
        <div className="container">
          <div data-w-id="9b2922ad-09b1-aebb-0430-7dee623c62c0"
               className="w-layout-grid about-intro-grid extra-1">
            <div id="w-node-_9b2922ad-09b1-aebb-0430-7dee623c62c1-81c14468" className="about-head-text-wrap">
              <div className="div-block-9"><h1 className="heading-jumbo">Hello, I'm Elvis!</h1></div>
              <p className="paragraph-light bigger">
                I am an mobile indie app creator, full-time web developer, and a solopreneur. With a passion for innovation and a love for crafting exceptional user experiences, I thrive on bringing ideas to life through technology.
              </p>
            </div>
            <StaticImage
              src="../images/es-2.jpg"
              quality={100}
              formats={["jpg",]}
              alt="Elvis Baltais"
              className={'detail-image pic-of-me'}
            />
          </div>
        </div>
      </div>
      <iframe id={"substack-frame"} src="https://elvisbaltais.substack.com/embed" width="100%" height="320" style={{ border: "1px solid #EEE", background: "white"}} frameBorder="0" scrolling="no"></iframe>
      <div className="section" id="about">
        <div className="container">
          <div className="w-layout-grid skills-grid">
            <div id="w-node-dac044b7-be90-60f3-414d-4224828ffd8a-81c14468" className="skillset-wrap">
              <h1 className="heading-3">ABOUT ME</h1>
              <p className="paragraph-light">
                I'm web & mobile architect with 10+ years of experience in developing, finding best
                solutions
                and maintaining custom apps using latest technologies.
                From product idea to Apple or Play Store.<br />
                <br />
                I have launched multiple apps and they are used by thousands of users. Still trying to learn and grow even more.
                <br />
                <br />
                Sharing insights and stories about my journey.
                <br />
                <br />
                Self-funded Indie app developer.

                <br />
                <br />
                Explorer and traveler.
              </p>
              {false && <div id="w-node-dac044b7-be90-60f3-414d-4224828ffd90-81c14468" className="div-block-10">
                <div className="service-name-text">Web architecture</div>
                <div className="service-name-text">Typescript</div>
                <div className="service-name-text">React</div>
                <div className="service-name-text">React Native</div>
                <div className="service-name-text">Angular</div>
                <div className="service-name-text">Node</div>
                <div className="service-name-text">IOS and Android development</div>
                <div className="service-name-text">PHP</div>
                <div className="service-name-text">MYSQL</div>
                <div className="service-name-text">Social Media Marketing</div>
              </div>}
            </div>


            <div id="w-node-dac044b7-be90-60f3-414d-4224828ffd8f-81c14468"
                 className="services-items-grid">
              <StaticImage
                src="../images/insta-2.jpg"
                quality={100}
                formats={["jpg",]}
                alt="Elvis Baltais"
                className={'detail-image pic-of-me'}
              />
            </div>
          </div>
          <h3>My Apps</h3>
          <div className="w-layout-grid" id="services">
            {false && <ul id="w-node-dac044b7-be90-60f3-414d-4224828ffda6-81c14468" role="list" className="w-list-unstyled">
              <li>
                <div>
                  <h1 className="heading-3">Services</h1>
                  <div className="paragraph-light cc-position-name">I offer consulting, developing and maintaining
                    existing and new projects.
                  </div>
                </div>
                <br/>
              </li>
              <li className="featured-item-wrap">* Determining best strategies & technologies for project architecture
              </li>
              <li className="featured-item-wrap">* Developing and maintaining web applications and progressive web
                apps
              </li>
              <li className="featured-item-wrap">* Developing and maintaining projects using latest technologies</li>
              <li className="featured-item-wrap">* Developing Apis and Microservices</li>
              <li className="featured-item-wrap">* Consulting on app/website/startup development</li>
              <li className="featured-item-wrap">
                <div className="paragraph-light cc-position-name">
                  <br/>For more specific and detailed information please contact me.
                </div>
              </li>
            </ul>}
            <div className="app-feature">

                <a href={"https://apps.apple.com/us/app/way-of-the-runes-norse-rune/id6449002811"} target="_blank" className={"app-feature-image"}>
                    <StaticImage
                    src="../images/app-img.png"
                    quality={100}
                    formats={["png"]}
                    alt="Way of The runes"
                    className={''}
                />
                </a>
            </div>
            <div className="app-feature">
              <a href={"https://apps.apple.com/us/app/manifest-law-of-attraction/id6449684499?platform=iphone"} target="_blank" className={"app-feature-image"}>
                <StaticImage
                  src="../images/mastermind-app.png"
                  quality={100}
                  formats={["png"]}
                  alt="Masterind app"
                  className={''}
                />
              </a>
            </div>
            <div className="personal-features-grid app-feature">
              <a href={"https://apps.apple.com/us/app/mastermind-stoic-joy-bliss/id6478835768"} target="_blank" className={"app-feature-image"}>
                <StaticImage
                  src="../images/manifest-app.png"
                  quality={100}
                  formats={["png"]}
                  alt="Manifest app"
                  className={''}
                />
              </a>
            </div>
            {/*<div className="personal-features-grid">*/}
            {/*  /!*<div id="w-node-dac044b7-be90-60f3-414d-4224828ffda1-81c14468">*!/*/}
            {/*  /!*  <h3>News</h3>*!/*/}
            {/*  /!*  <div className="paragraph-light cc-position-name">Blog posts and news about me and my work.*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*  /!*<ul id="w-node-dac044b7-be90-60f3-414d-4224828ffda6-81c14468" role="list" className="w-list-unstyled">*!/*/}
            {/*  /!*  <li className="featured-item-wrap">*!/*/}
            {/*  /!*    <div className="speaking-text">Rune of the Day</div>*!/*/}
            {/*  /!*    <div className="speaking-detail">(Blog)</div>*!/*/}
            {/*  /!*    <a href="https://wayoftherunes.com/blog/rune-of-the-day/" className="reference-link" target={"_blank"}>*!/*/}
            {/*  /!*      New blog post about rune of the day*!/*/}
            {/*  /!*    </a>*!/*/}
            {/*  /!*  </li>*!/*/}
            {/*  /!*  <li className="featured-item-wrap">*!/*/}
            {/*  /!*    <div className="speaking-text">Find out your birth rune</div>*!/*/}
            {/*  /!*    <div className="speaking-detail">(Blog)</div>*!/*/}
            {/*  /!*    <a href="https://wayoftherunes.com/blog/birth-rune/" className="reference-link" target={"_blank"}>*!/*/}
            {/*  /!*      New blog post about birth runes*!/*/}
            {/*  /!*    </a>*!/*/}
            {/*  /!*  </li>*!/*/}
            {/*  /!*  <li className="featured-item-wrap">*!/*/}
            {/*  /!*    <div className="speaking-text">Find answers with Rune reading</div>*!/*/}
            {/*  /!*    <div className="speaking-detail">(Blog)</div>*!/*/}
            {/*  /!*    <a href="https://wayoftherunes.com/blog/find-answers-with-rune-reading/" className="reference-link" target={"_blank"}>*!/*/}
            {/*  /!*      New blog post about rune reading*!/*/}
            {/*  /!*    </a>*!/*/}
            {/*  /!*  </li>*!/*/}
            {/*  /!*  <li className="featured-item-wrap">*!/*/}
            {/*  /!*    <div className="speaking-text">Created mobile app "Way of the runes"</div>*!/*/}
            {/*  /!*    <div className="speaking-detail">(Android)</div>*!/*/}
            {/*  /!*    <a href="https://play.google.com/store/apps/details?id=com.elvisbaltais.wayOfTheRunes" className="reference-link" target={"_blank"}>Create a new mobile app for android "Way of the runes"</a>*!/*/}
            {/*  /!*  </li>*!/*/}
            {/*  /!*</ul>*!/*/}
            {/*  {false &&*/}
            {/*    <div id="w-node-dac044b7-be90-60f3-414d-4224828ffdd8-81c14468">*/}
            {/*      <div className="upcoming-wrap">*/}
            {/*        <div className="label cc-speaking-label">Upcoming</div>*/}
            {/*        <ul role="list" className="w-list-unstyled">*/}
            {/*          <li>*/}
            {/*            <div className="speaking-text">Dolor interdum nulla</div>*/}
            {/*            <div className="speaking-detail">(Apr 2021)</div>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <div className="speaking-text">Varius enim in eros elementum tristique</div>*/}
            {/*            <div className="speaking-detail">(Dec 2020)</div>*/}
            {/*          </li>*/}
            {/*          <li>*/}
            {/*            <div className="speaking-text">Lorem ipsum dolor sit amet</div>*/}
            {/*            <div className="speaking-detail">(Mar 2020)</div>*/}
            {/*          </li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*      <div className="label">Past</div>*/}
            {/*      <ul role="list" className="w-list-unstyled">*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Duis cursus, mi quis viverra ornare</div>*/}
            {/*          <div className="speaking-detail">(Dec 2018)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Suspendisse varius enim</div>*/}
            {/*          <div className="speaking-detail">(Nov 2018)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Lorem ipsum dolor sit amet</div>*/}
            {/*          <div className="speaking-detail">(Jun 2018)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Far far away, behind the word</div>*/}
            {/*          <div className="speaking-detail">(Apr 2018)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Dolor interdum nulla</div>*/}
            {/*          <div className="speaking-detail">(Nov 2017)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Varius enim in eros elementum tristique</div>*/}
            {/*          <div className="speaking-detail">(Jan 2017)</div>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*          <div className="speaking-text cc-past-speaking">Lorem ipsum dolor sit amet</div>*/}
            {/*          <div className="speaking-detail">(Jul 2016)</div>*/}
            {/*        </li>*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*  {false &&*/}
            {/*    <div id="w-node-dac044b7-be90-60f3-414d-4224828ffe12-81c14468"><h3>Speaking</h3>*/}
            {/*      <div className="paragraph-light cc-position-name">The countries Vokalia and Consonantia, there live*/}
            {/*        the*/}
            {/*        blind texts.*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*</div>*/}

          </div>
          <div className="w-layout-grid" id="contacts">
            <div>
              <div className="upcoming-wrap">
                <ul role="list" className="w-list-unstyled">
                  <li>
                    <div>
                      <h3>Contacts and more</h3>
                      <div className="paragraph-light cc-position-name">You can contact me with provided info below.
                      </div>
                    </div>
                    <br/>
                  </li>
                  {/*<li className="featured-item-wrap">*/}
                  {/*  <div className="speaking-text">LinkedIn -</div>*/}
                  {/*  <div className="speaking-detail">*/}
                  {/*    <a href="https://www.linkedin.com/in/elvis-web-developer-04545779/" className="reference-link"*/}
                  {/*       target="blank_">Elvis Baltais</a>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                  <li className="featured-item-wrap">
                    <div className="speaking-text">E-mail -</div>
                    <div className="speaking-detail">
                      <a href="mailto:elvis.baltais.i@gmail.com" className="reference-link">elvis.baltais.i@gmail.com</a>
                    </div>
                  </li>
                  <li className="featured-item-wrap">
                    <div className="speaking-text">X (Twitter) -</div>
                    <div className="speaking-detail">
                      <a href="https://twitter.com/Elvis_Baltais" className="reference-link" target={"_blank"}>@Elvis_Baltais</a>
                    </div>
                  </li>
                  <li className="featured-item-wrap">
                    <div className="speaking-text">Apple store -</div>
                    <div className="speaking-detail">
                      <a href="https://t.co/o1IE6YTqGA" className="reference-link" target={"_blank"}>Elvis Baltais</a>
                    </div>
                  </li>
                  <li className="featured-item-wrap">
                    <div className="speaking-text">Google play store -</div>
                    <div className="speaking-detail">
                      <a href="https://play.google.com/store/apps/developer?id=Elvis+Baltais" className="reference-link" target={"_blank"}>Elvis Baltais</a>
                    </div>
                  </li>
                  {/*<li className="featured-item-wrap">*/}
                  {/*  <div className="speaking-text">Facebook -</div>*/}
                  {/*  <div className="speaking-detail"><a href="https://www.facebook.com/elvis20191"*/}
                  {/*                                      className="reference-link" target="blank_">Elvis Baltais</a></div>*/}
                  {/*</li>*/}
                  { false &&
                      <>
                          <li className="featured-item-wrap">
                              <div className="speaking-text">WhatsApp -</div>
                              <div className="speaking-detail"><a href="#" className="reference-link">(comming soon)</a></div>
                          </li>
                          <li className="featured-item-wrap">
                              <div className="speaking-text">Github Repo -</div>
                              <div className="speaking-detail"><a href="#" className="reference-link">(comming soon)</a></div>
                          </li>
                          <li className="featured-item-wrap">
                              <div className="speaking-text">Instagram -</div>
                              <div className="speaking-detail"><a href="#" className="reference-link">(comming soon)</a></div>
                          </li>
                      </>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>

        {false &&
          <div className="container">
            <div className="social-media-heading">
              <h1 className="heading-6">On Working With Me</h1>
              <div className="w-layout-grid grid-2">
                <div id="w-node-e179e2e2-9ea9-d7ba-1757-d5875fe285d8-81c14468" className="testimony-person">
                  {/*<img src="../images/6003bc8a500ee553547067e4_1560732160364.jpeg" loading="lazy" sizes="150px"*/}
                  {/*     srcSet="https://uploads-ssl.webflow.com/5fa25266badbdb239c79ef86/6003bc8a500ee553547067e4_1560732160364-p-500.jpeg 500w, https://uploads-ssl.webflow.com/5fa25266badbdb239c79ef86/6003bc8a500ee553547067e4_1560732160364.jpeg 800w"*/}
                  {/*     alt="" className="image-3">*/}
                  <h5 className="heading-4">Lana Kinney</h5>
                  <div className="text-block-3">UX Researcher at AKQA</div>
                </div>
                <div id="w-node-dac044b7-be90-60f3-414d-4224828ffe1b-81c14468"
                     className="paragraph-light testimonial">"Sebastian is a guru at all things UX design, consistently
                  producing intuitive, modern, and bold designs. He is a natural problem solver in the design space so
                  everyone went to him if they were stuck on a feature or flow. He has a arsenal of design skills
                  including animation and graphic design. Not to mention, Seb was a design team lead, which means he
                  managed a full team of both onshore and offshore designs reviewing and providing feedback on all of
                  their designs... Any business would be lucky to have him."
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {false &&
        <div className="section">
          <div className="w-layout-grid social-media-grid">
            {/*<StaticImage*/}
            {/*  src="../images/5fa252663b6e7c615c9c0c92_placeholder 3.svg"*/}
            {/*  quality={100}*/}
            {/*  alt="Elvis Baltais"*/}
            {/*  className={'socials-2'}*/}
            {/*/>*/}
            {/*<StaticImage*/}
            {/*  src="../images/5fa252663b6e7c615c9c0c92_placeholder 3.svg"*/}
            {/*  quality={100}*/}
            {/*  alt="Elvis Baltais"*/}
            {/*  className={'socials-3'}*/}
            {/*/>*/}
            {/*<StaticImage*/}
            {/*  src="../images/5fa252663b6e7c615c9c0c92_placeholder 3.svg"*/}
            {/*  quality={100}*/}
            {/*  alt="Elvis Baltais"*/}
            {/*  className={'socials-4'}*/}
            {/*/>*/}
          </div>
        </div>
      }
      {/*<ContactsForm />*/}
      <footer id="footer" className="footer">
        <div className="container w-container">
          { false &&
            <div className="footer-flex-container">
              <div className="div-block-8">
                <a href="https://dribbble.com/bombasty/shots" target="_blank" className="footer-link">Dribbble</a>
                <a href="https://www.linkedin.com/in/sebastianpetravic/" target="_blank"
                   className="footer-link">LinkedIn</a>
                <a href="https://sebastianpetravic.webflow.io/about" aria-current="page"
                   className="footer-link w--current">About</a>
                <a href="http://sebastianpetravic.webflow.io/#wf-form-Email-Form" className="footer-link">Contact</a>
              </div>
            </div>
          }
          <div className="paragraph-small">Copyright © {new Date().getFullYear()}, Elvis Baltais. All rights
            reserved.</div>
        </div>
      </footer>
    </>
  );
};